import React, { useMemo } from 'react';
import Avatar from '../../../../Assests/avatar-1.png';
import { InvoiceDoc } from '../../../../types/invoice.type';
import { format } from 'date-fns';
import { formatCurrency } from '../../../../utils';

type Props = {
  invoice?: InvoiceDoc;
};

export default function Invoice({ invoice }: Props) {
  const subtotal = useMemo(() => {
    const calculateAmount = (price: number, quantity: number) => {
      return price * quantity;
    };
    let total = 0;
    (invoice?.products ?? []).forEach((product: any) => {
      total += calculateAmount(product?.product?.price || 0, product?.quantity || 0);
    });
    return total;
  }, [invoice?.products]);

  const discountAmount = useMemo(() => {
    if (invoice?.discount?.type === 'percentage') {
      return ((invoice?.discount?.value || 0) / 100) * subtotal;
    } else {
      return invoice?.discount?.value || 0;
    }
  }, [invoice?.discount?.type, invoice?.discount?.value, subtotal]);

  const taxAmount = useMemo(() => {
    if (invoice?.tax?.type === 'percentage') {
      return ((invoice?.tax?.value || 0) / 100) * subtotal;
    } else {
      return invoice?.tax?.value || 0;
    }
  }, [invoice?.tax?.type, invoice?.tax?.value, subtotal]);

  return (
    <div>
      {invoice && (
        <div className="panel mt-9 py-12 rounded-[20px] bg-white">
          <div className="p-5 flex flex-wrap justify-between items-center gap-4 px-4 border-b pb-6">
            <div className="flex items-center gap-6">
              <div className="shrink-0">
                <img
                  className="h-[50px] w-[50px] rounded-full object-cover saturate-50 group-hover:saturate-100"
                  src={
                    invoice.businessID?.profileImageURL
                      ? invoice.businessID?.profileImageURL
                      : Avatar
                  }
                  alt="avatar"
                />
              </div>
              <p className="text-xl font-inter font-medium">{invoice.businessID?.name}</p>
            </div>

            <div className="px-4 text-right">
              <div className="mt-6 space-y-1 text-white-dark">
                <div>{invoice.businessID?.addresses[0]?.address}</div>
                <div>{invoice.businessID?.addresses[0]?.state}</div>
                <div>{invoice.businessID?.phone}</div>
                <div>{invoice.businessID?.email}</div>
              </div>
            </div>
          </div>
          <div className="p-5 flex flex-col flex-wrap justify-between gap-6 lg:flex-row mt-14 px-4">
            <div className="flex-1">
              <div className="space-y-1 text-black/70 text-base font-medium">
                <div className="font-semibold text-black">Invoice to</div>
                <div>{invoice.sellerName}</div>
                <div>
                  {invoice.address},{invoice.state}
                </div>
                <div>{invoice.email}</div>
                <div>{invoice.phone}</div>
              </div>
            </div>
            <div className="px-4 text-right">
              <div className="space-y-1 text-white-dark">
                <div className="font-semibold text-black/70">Invoice details</div>
                <div>Invoice no: {invoice.number}</div>
                <div>Date: {format(new Date(invoice.date!), 'dd, MMM yyyy')}</div>
                <div>
                  Due date: {format(new Date(invoice.requiredEnd!), 'dd, MMM yyyy')}
                </div>
                <div>Terms of trade: missing from the api</div>
              </div>
            </div>
          </div>
          {/* Table */}
          <div className="mt-6">
            <table>
              <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                <tr>
                  <th>Product</th>
                  <th>Description</th>
                  <th>Unit price</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody className="bg-[#B9D6F224]">
                {invoice.products?.map((item) => (
                  <>
                    <tr>
                      <td className="whitespace-nowrap">{item.product?.name}</td>
                      <td className="whitespace-nowrap">{item.product?.description}</td>
                      <td className="whitespace-nowrap">
                        {formatCurrency(item.product?.currency, item.product?.price || 0)}
                      </td>
                      <td className="whitespace-nowrap">{item.quantity}</td>
                      <td className="whitespace-nowrap">
                        {formatCurrency(
                          item.product?.currency,
                          (item.product?.price || 0) * item.quantity
                        )}
                      </td>
                    </tr>
                    <tr className="h-2"></tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="border-b mx-10 mb-10 pb-10 flex items-center justify-end mt-9 px-10">
            <div className="sm:w-2/5">
              <div className="flex items-center justify-between">
                <div>Subtotal</div>
                <div>{formatCurrency(invoice.currency, subtotal)}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Discount</div>
                <div>{formatCurrency(invoice.currency, discountAmount)}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Tax</div>
                <div>{formatCurrency(invoice.currency, taxAmount)}</div>
              </div>
              <div className="mt-4 py-6 rounded-2xl bg-[#B9D6F2] p-4 flex items-center justify-between font-semibold">
                {invoice.status === 'approved_for_financing' ||
                invoice.status === 'outstanding' ||
                invoice.status === 'paid' ? (
                  <div>Outstanding Amount</div>
                ) : (
                  <div>Total Amount</div>
                )}
                <h2>
                  {formatCurrency(
                    invoice.currency,
                    invoice.outstanding! > 1 ? invoice.outstanding : invoice.amount
                  )}
                </h2>
              </div>
            </div>
          </div>

          <div className="p-8 px-10 flex items-center justify-between">
            <div className="font-medium font-inter text-base text-black">
              <p className="font-semibold">Note</p>
              {invoice.note}
            </div>
            <div className="sm:w-2/5">
              <p className="font-semibold">Payment method</p>
              <div className="flex items-center justify-between mt-5">
                <div>Bank name</div>
                <div>{invoice.bankName}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Account number</div>
                <div>{invoice.bankAccountNumber}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Account name</div>
                <div>{invoice.bankAccountName}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
