import { PaystackButton } from 'react-paystack';
import Button from '../Form/Button';
import { InfoModal } from './InfoModal';
import { PaystackSuccessDto } from '../../types/payment.type';
// import { useEffect } from 'react';
// import { toast } from 'react-toastify';
// import { usePayInvoice } from '../../hooks/queries-and-mutations/invoice';

type Props = {
  title?: string;
  content: string;
  actionText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  cancelClassName?: string;
  actionClassName?: string;
  widthClass?: number;
  email?: string;
  amount?: number;
  payStackBtnStyle?: string;
  paystackBtnText?: string;
  continuePayment?: boolean;
  onPaymentClose?: () => void;
  businessId?: string;
  invoiceId?: string;
};

export default function ConfirmModal({
  content,
  title,
  actionText,
  cancelText,
  onConfirm,
  onCancel,
  isSubmitting,
  cancelClassName,
  actionClassName,
  widthClass = 45,
  email,
  amount,
  payStackBtnStyle,
  paystackBtnText,
  continuePayment = false,
  onPaymentClose,
  businessId,
  invoiceId,
}: Props) {
  // const { isSuccess, isError } = usePayInvoice();

  // useEffect(() => {
  //   if (isSuccess && !isError) {
  //     toast.success('Invoice has been approved');
  //     onCancel();
  //   } else if (isError) {
  //     toast.success('payment declined');
  //   }
  // }, [isError, isSuccess]);

  const onPaymentSuccess = (payload: PaystackSuccessDto) => {
    if (
      // payload.message === 'Approved' &&
      // payload.status === 'success' &&
      invoiceId &&
      businessId
    ) {
      console.log(businessId, payload, 'updatesd');

      // mutate({ businessId, reference: payload.reference });
    }
  };
  return (
    <InfoModal width={`w-[${widthClass}%]`} className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          <h2 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0">
            {title}{' '}
          </h2>
          <div className="max-w-xs p-5 text-center">
            <p className="text-xl font-medium font-inter">{content}</p>
          </div>
          <div className="flex items-center justify-center gap-3 my-3 mt-10">
            <Button
              variant="custom"
              size="custom"
              className={cancelClassName}
              label={cancelText || 'No'}
              onClick={onCancel}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
            {!continuePayment && (
              <Button
                variant="custom"
                size="custom"
                className={actionClassName}
                label={actionText || 'Yes'}
                onClick={onConfirm}
                disabled={isSubmitting}
                loading={isSubmitting}
              ></Button>
            )}
            {continuePayment && (
              <PaystackButton
                onSuccess={onPaymentSuccess}
                onClose={onPaymentClose}
                email={email ?? ''}
                amount={(amount && amount * 100) ?? 0}
                publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string}
                className={payStackBtnStyle}
                text={paystackBtnText}
              />
            )}
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
