import React, { useCallback } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Create from './Pages/Onboarding/Create';
import Email from './Pages/Onboarding/Email';
import Final from './Pages/Onboarding/Final';
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Verificatuion from './Pages/Onboarding/Verificatuion';
import ForgetPass from './Pages/Onboarding/ForgetPass';
import RestPass from './Pages/Onboarding/ResetPass';
import UserRoute from './ProtectedRoute/UserRoute';
import EarlyAccess from './Pages/Onboarding/EarlyAccess';
import AuthLayout from './Layout/AuthLayout';
import Login from './Pages/v2/Authentication/Login';
import ForgotPassword from './Pages/v2/Authentication/ForgotPassword';
import CreatePassword from './Pages/v2/Authentication/CreatePassword';
import CreateAccount from './Pages/v2/Authentication/CreateAccount';
import Onboarding from './Pages/v2/Onboarding';
import OnBoardingLayout from './Layout/OnBoardingLayout';
import UserLayout from './Layout/UserLayout';
import Dashboard from './Pages/v2/User';
import Vendors from './Pages/v2/User/Vendors';
import Profile from './Pages/v2/User/Settings/Profile';
import AddAccount from './Pages/v2/User/Settings/Account/index';
import VendorProfile from './Pages/v2/User/Vendors/VendorProfile';
import Landing from './Pages/v2/Landing';
import LandingLayout from './Layout/LandingLayout';
import Settings from './Pages/v2/User/Settings';
import Account from './Pages/v2/User/Settings/Account';
import Compliance from './Pages/v2/User/Settings/Compliance';
import General from './Pages/v2/User/Settings/General';
import CreateInvoice from './Pages/v2/User/Invoices/CreateInvoice';
import AuditTrail from './Pages/v2/User/Audit';
import Invoice from './Pages/v2/User/Invoices';
import InvoiceDraft from './Pages/v2/User/Invoices/InvoiceDraft';
import InvoiceView from './Pages/v2/User/Invoices/InvoiceView';
import Products from './Pages/v2/User/Products';
import Approval from './Pages/v2/User/Settings/Approval';
import Collaborators from './Pages/v2/User/Settings/Collaborators';
import Department from './Pages/v2/User/Settings/Department';
import Transactions from './Pages/v2/User/Transactions';
import ErrorPage from './Pages/404/ErrorPage';

function App() {
  const notify = useCallback((type: string, message: string) => {
    if (type === 'info') {
      toast.info(message);
    }
    if (type === 'success') {
      toast.success(message);
    }
    if (type === 'warn') {
      toast.warn(message);
    }
    if (type === 'error') {
      toast.error(message);
    }
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <Routes>
        <Route element={<LandingLayout />}>
          <Route path="/" element={<Landing />} />
        </Route>
        <Route path="/create-account" element={<Create notify={notify} />} />
        <Route path="/early-access" element={<EarlyAccess notify={notify} />} />

        <Route element={<AuthLayout />} path="auth">
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/auth/create-password/:email/:token"
            element={<CreatePassword />}
          />
          <Route path="/auth/create-account" element={<CreateAccount />} />
        </Route>

        <Route element={<OnBoardingLayout />} path="onboarding">
          <Route path="/onboarding/:stage" index={true} element={<Onboarding />} />
        </Route>

        <Route element={<UserLayout />}>
          <Route path="/dashboard" index={true} element={<Dashboard />} />
          <Route path="/vendors" index={true} element={<Vendors />} />
          <Route path="/vendors/:vendorId" index={true} element={<VendorProfile />} />
          <Route path="/invoices" index={true} element={<Invoice />} />
          <Route path="/invoices-draft" index={true} element={<InvoiceDraft />} />
          <Route path="/invoices/create" element={<CreateInvoice />} />
          <Route path="/invoices/view/:invoiceId" element={<InvoiceView />} />
          <Route path="/products" index={true} element={<Products />} />
          <Route path="/transactions" index={true} element={<Transactions />} />
          <Route path="/audit-trail" index={true} element={<AuditTrail />} />
          <Route path="/settings" element={<Settings />}>
            <Route path="/settings" element={<General />} />
            <Route path="/settings/department" element={<Department />} />
            <Route path="/settings/compliance" element={<Compliance />} />
            <Route path="/settings/collaborators" element={<Collaborators />} />
            <Route path="/settings/approval" element={<Approval />} />
            <Route path="/settings/account" element={<Account />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/switch-account" element={<AddAccount />} />
        </Route>

        <Route path="/forgot-password" element={<ForgetPass notify={notify} />} />
        <Route
          path="/reset-password/:email/:token"
          element={<RestPass notify={notify} />}
        />
        <Route path="/email-confirmation" element={<Email />} />
        <Route
          path="/activation/:email/:token"
          element={<Verificatuion notify={notify} />}
        />
        {/* User route */}
        <Route element={<UserRoute notify={notify} />}>
          <Route path="/create-business/:stage" element={<Final notify={notify} />} />
          {/* <Route path="/user/:page" element={<Dashboard notify={notify} />} />
          <Route path="/user/:page/:id" element={<Dashboard notify={notify} />} /> */}
          {/* </Route> */}
          {/* Admin Routes */}
          {/* <Route path="/admin/login" element={<AdminLogin notify={notify} />} /> */}
          {/* <Route element={<AdminRoute notify={notify} />}> */}
          {/* <Route path="/admin/:page" element={<Admin notify={notify} />} /> */}
          {/* <Route path="/admin/:page/:id" element={<Admin notify={notify} />} /> */}
          <Route path="*" element={<ErrorPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
