import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approveInvoice,
  confirmInvoicePaymentProof,
  createInvoice,
  declineInvoice,
  downloadInvoice,
  getInvoice,
  getInvoicePaymentProof,
  payForInvoice,
  submitPaymentProof,
  updateInvoice,
  verifyInvoicePayment,
} from '../../service/invoice';
import { toast } from 'react-toastify';
import { logout } from '../../thunks/account-thunk';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';

export const useGetInvoice = ({
  invoiceId,
  businessId,
}: {
  businessId: string;
  invoiceId: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(['invoice'], () => getInvoice(businessId, invoiceId), {
    refetchOnWindowFocus: false,
    enabled: !!invoiceId,
    onError: (error: Error) => {
      if (error.message === 'Session expired please login again') {
        dispatch(logout());
        navigate('/auth/login');
      }
    },
  });
};

export const useGetInvoicePaymentProof = ({
  invoiceId,
  businessId,
}: {
  businessId: string;
  invoiceId: string;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['invoice-payment-proof'],
    () => getInvoicePaymentProof(businessId, invoiceId),
    {
      refetchOnWindowFocus: false,
      enabled: !!invoiceId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useConfirmInvoicePayment = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      referenceId,
    }: {
      businessId: string;
      referenceId: string;
    }): Promise<void> => {
      return confirmInvoicePaymentProof(businessId, referenceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['confirm-invoice-payment']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useSubmitPaymentProof = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      invoiceId,
      payload,
    }: {
      businessId: string;
      invoiceId: string;
      payload: any;
    }): Promise<void> => {
      return submitPaymentProof(businessId, invoiceId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['submit-invoice-payment-proof']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useVerifyPaymentProof = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      payemntId,
    }: {
      businessId: string;
      payemntId: string;
    }): Promise<void> => {
      return verifyInvoicePayment(businessId, payemntId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['verify-invoice-payment']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      payload,
      status,
    }: {
      businessId: string;
      payload: any;
      status: boolean;
    }): Promise<void> => {
      return createInvoice(businessId, payload, status);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['create-invoice']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useUpdateInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      updatedPayload,
      invoiceId,
    }: {
      businessId: string;
      updatedPayload: any;
      invoiceId: string;
    }): Promise<void> => {
      return updateInvoice(businessId, updatedPayload, invoiceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['update-invoice']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry, an error has occurred while updating your invoice'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useApproveInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      invoiceId,
    }: {
      businessId: string;
      invoiceId: any;
    }): Promise<void> => {
      return approveInvoice(businessId, invoiceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['approve-invoice']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useDeclineInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      payload,
      invoiceId,
    }: {
      businessId: string;
      payload: any;
      invoiceId: any;
    }): Promise<void> => {
      return declineInvoice(businessId, payload, invoiceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['decline-invoice']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ??
            'Sorry!, An error has occurred while trying to complete this request'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useDownloadInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      invoiceId,
    }: {
      businessId: string;
      invoiceId: string;
    }): Promise<void> => {
      return downloadInvoice(businessId, invoiceId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['download-invoice']);
      },
      onError: (error: Error) => {
        toast.error(
          error.message ?? 'Sorry! We could not download this invoice at this time.'
        );
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const usePayInvoice = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      reference,
    }: {
      businessId: string;
      reference: string;
    }): Promise<void> => {
      return payForInvoice(businessId, reference);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['verify-invoice-payment']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
