import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../../../Components/Form/TextField';
import Button from '../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../Components/Icon';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import {
  useArchiveDept,
  useCreateDepartment,
  useGetDepartment,
  useUpdateDepartment,
} from '../../../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface IData {
  name: string;
  departmentID: string;
  isEdit: boolean;
}
export default function Department() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getIdSelected, setGetIdSelected] = useState<string>(' ');

  const { docs } = useAppSelector(selectBusiness);

  const { data, refetch } = useGetDepartment({
    businessId: docs[0].businessID._id,
    page: 1,
  });

  const { mutate, isSuccess: isSaved, isError } = useCreateDepartment();

  const {
    mutate: update,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateDepartment();

  const { mutateAsync: archiveDept } = useArchiveDept();

  const departments = useMemo(() => {
    if (data && data?.docs.length) {
      return data?.docs.map((dp) => ({
        name: dp.name ?? '',
        departmentId: dp._id ?? '',
        isEdit: false,
      }));
    } else {
      return [];
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      departments,
    },
    validationSchema: Yup.object({
      departments: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Department name is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [deptRoles, setDeptRoles] = useState(formik?.values?.departments);

  const addMoreDepartment = () => {
    if (docs[0].businessID.status === 'active') {
      formik.values.departments.push({
        name: '',
        departmentId: '',
        isEdit: true,
      });
      formik.setFieldValue('departments', [...formik.values.departments]);
    } else {
      toast.info('You need to verify your business first');
    }
  };

  useEffect(() => {
    if (isSaved && !isError) {
      setIsSubmitting(false);
      toast.success('Department created');
      refetch();
    } else if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSaved]);

  useEffect(() => {
    if (isUpdated && !isUpdateError) {
      setIsSubmitting(false);
      toast.success('Department updated');
      refetch();
    } else if (isUpdateError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateError, isUpdated]);

  useEffect(() => {
    //Runs for when I add a new department
    setDeptRoles(formik.values.departments);
  }, [formik.values.departments, deptRoles]);

  const handleArchiveADept = async (id: string) => {
    archiveDept({ businessId: docs[0].businessID._id, id })
      .then(() => {
        toast.success('Department archived successfully');
        setDeptRoles((prev) => {
          const updatedDeptRoles = prev.filter(
            (dept) => dept.departmentId !== getIdSelected
          );
          return updatedDeptRoles;
        });
        refetch();
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err.message ?? 'Sorry!, An error as occurred');
      });
  };

  const handleAddDept = async (departmentId: string, name: string, index: number) => {
    if (departmentId) {
      setIsSubmitting(true);
      const payload = {
        name: name,
        id: departmentId,
      };
      update({
        payload,
        businessId: docs[0].businessID?._id,
      });
      formik.setFieldValue(`depatments[${index}].isEdit`, false);
    } else {
      mutate(
        {
          name: formik.values.departments[index]?.name,
          businessId: docs[0].businessID?._id,
        },
        {
          onSuccess: (response) => {
            const newDepartment: IData = {
              name: response.name,
              departmentID: response._id,
              isEdit: false,
            };
            setDeptRoles((prev: any) => {
              const prevDepts = [...prev];
              prevDepts[index] = newDepartment;
              return prevDepts;
            });
          },
        }
      );
    }
  };

  return (
    <div className="w-full">
      <CheckPermissions
        requiredPermissions={['create_business_department', 'update_business_department']}
      >
        <div className="py-5 border-b pl-9">
          <p className="text-2xl font-semibold text-black font-inter">Department</p>
        </div>

        {formik.values.departments.length >= 1 ? (
          <div className="p-8 pb-16">
            {deptRoles.map((department, index) => (
              <div key={index} className="flex items-center w-full gap-10 mt-5">
                <div className="w-3/5">
                  <TextField
                    name={`departments[${index}].name`}
                    type="text"
                    value={formik.values.departments[index]?.name}
                    disabled={!department.isEdit}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(`departments[${index}].name`, e.target.value);
                    }}
                    onBlur={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue('departmentId', department.departmentId);
                    }}
                    label={`Department ${index + 1}`}
                    error={
                      formik.touched.departments
                        ? formik.errors.departments?.[index] &&
                          formik.errors.departments.length > 0
                          ? JSON.parse(JSON.stringify(formik.errors.departments[index]))
                              .name
                          : ''
                        : ''
                    }
                    placeholder="Add a department"
                    className="bg-[#FFFFFF8C] text-black w-full"
                  />
                </div>
                <div>
                  {department.departmentId && !department.isEdit && (
                    <div className="flex items-center gap-3 space-x-2 divide-x">
                      <button
                        type="button"
                        onClick={() => {
                          formik.setFieldValue(`departments[${index}].isEdit`, true);
                        }}
                      >
                        <Icon name={Icons.EditPencil} />
                      </button>
                      <button
                        onClick={() => {
                          setGetIdSelected(department.departmentId);
                          handleArchiveADept(department.departmentId);
                        }}
                        className="pl-3"
                        type="button"
                      >
                        <Icon name={Icons.Delete} />
                      </button>
                    </div>
                  )}
                  {department.isEdit && (
                    <Button
                      type="button"
                      onClick={() => {
                        handleAddDept(department.departmentId, department.name, index);
                      }}
                      variant="primary"
                      size="sm"
                      label="Save"
                      className="h-12 mt-2"
                      disabled={isSubmitting || formik.isSubmitting}
                      loading={isSubmitting || formik.isSubmitting}
                    />
                  )}
                </div>
              </div>
            ))}

            <div
              className="flex items-center mt-8"
              role="button"
              tabIndex={0}
              onClick={addMoreDepartment}
            >
              <div className="flex items-center gap-1">
                <Icon name={Icons.Add} />
                <span className="text-sm font-medium text-[#000000]">
                  Add another department
                </span>
              </div>
              <div className="line ml-5" style={{ width: '78%' }} />
            </div>
          </div>
        ) : (
          <div className="m-10 text-lg font-medium text-black font-inter">
            <p>No department added yet</p>
            <div
              className="flex items-center gap-4"
              role="button"
              tabIndex={0}
              onClick={addMoreDepartment}
            >
              <Icon name={Icons.Add} />
              <span className="text-[#0353A4] text-sm"> Add new department</span>
            </div>
          </div>
        )}
      </CheckPermissions>
    </div>
  );
}
