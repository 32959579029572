import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import SearchInput from '../../../../Components/Form/SearchInput';
import FilterDropdown from '../../../../Components/Filter/FilterDropdown';
import { Icon, Icons } from '../../../../Components/Icon';
import Rating from '../../../../Components/Rating';
import EmptyRecord from '../../../../Components/EmptyRecord';
import AddVendorMenu from '../../../../Components/Modal/AddAccount/AddVendorMenu';
import Button from '../../../../Components/Form/Button';
import AddVendorModal from '../../../../Components/Modal/Vendor/AddVendorModal';
import AddBulkVendorModal from '../../../../Components/Modal/Vendor/AddBulkVendorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import { VendorDoc, VendorType } from '../../../../types/vendor.type';
import { useAppSelector } from '../../../../store/hooks';
import { getVendors } from '../../../../service/vendor';
import { selectBusiness } from '../../../../selectors/business-selector';
import { toast } from 'react-toastify';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../Assests/loader.gif';
import { format } from 'date-fns';
import Status from '../../../../Components/Status';
import Pagination from '../../../../Components/Pagination/Pagination';

const EnhancedAddVendorMenu = withCreatePortal(AddVendorMenu);
const EnhancedAddVendorModal = withCreatePortal(AddVendorModal);
const EnhancedAddBulkVendorModal = withCreatePortal(AddBulkVendorModal);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);

export default function Vendors() {
  const [showAddVendorMenu, setShowAddVendorMenu] = useState(false);
  const [showAddSingleVendor, setShowAddSingleVendor] = useState(false);
  const [showAddMultipleVendor, setShowAddMultipleVendor] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [vendorsData, setVendorsData] = useState<VendorType | null>(null);
  const [filteredData, setFilteredData] = useState<VendorDoc[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');

  const { docs } = useAppSelector(selectBusiness);

  const fetchData = useCallback(
    async (currentPage: number, filterBy?: string) => {
      const vendors = await getVendors(
        docs[0].businessID._id,
        currentPage,
        filterBy,
        10,
        true
      );
      setIsLoading(false);
      setVendorsData(vendors);
    },
    [docs]
  );
  useEffect(() => {
    if (docs[0]) {
      docs[0].businessID.status === 'active'
        ? null
        : toast.info('You need to verify your business');
    }
    setIsLoading(true);
    fetchData(1);
  }, [fetchData]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = vendorsData?.docs.filter(
      (item: any) =>
        item.vendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.goodsSupplied.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData as VendorDoc[]);
  };

  const paginationClick = (num: any) => {
    setIsLoading(true);
    fetchData(num, filterValue);
  };

  const selectFilter = (search: string) => {
    setIsLoading(true);
    fetchData(1, search);
    setFilterValue(search);
  };

  return (
    <div>
      <Breadcrumb text="Vendors" />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <div className="w-1/3">
            <SearchInput
              value={search}
              placeholder="Search"
              name={search}
              className="h-10"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="flex items-center gap-2">
            <FilterDropdown
              iconPosition="left"
              icon={<Icon name={Icons.Filter} />}
              setSelectedOrder={(val) => console.log('FilterDropdown', val)}
              label="Filter"
              handleTableSearch={(val) => selectFilter(val)}
              options={[
                { value: 'buyer', label: 'Buyer' },
                { value: 'supplier', label: 'Supplier' },
              ]}
              disabled={docs[0].businessID.status === 'active' ? false : true}
            />
            <CheckPermissions requiredPermissions={['invite_vendors']}>
              <Button
                type="button"
                variant="primary"
                size="custom"
                label="Add Vendor"
                className="px-5 py-2.5 text-sm"
                icon={<Icon name={Icons.Add} fill="#FFFFFF" width={15} height={15} />}
                iconPosition="left"
                iconLabelSpacing={10}
                onClick={() => {
                  setShowAddVendorMenu(true);
                  if (docs[0].businessID.status === 'active') {
                    setShowAddVendorMenu(true);
                  } else {
                    toast.info('Business is yet to be verified');
                  }
                }}
                disabled={docs[0].businessID.status === 'active' ? false : true}
              />
            </CheckPermissions>
          </div>
        </div>

        <CheckPermissions requiredPermissions={['invite_vendors', 'view_vendors']}>
          {isLoading ? (
            <div className="flex mt-24 flex-col justify-center items-center">
              <img src={Loader} className="w-32" alt="" />
            </div>
          ) : (
            <div className="relative overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
              <table className="w-full text-sm text-left text-black mt-3 font-inter font-medium">
                <thead className="border-b border-black/10 text-sm font-inter text-black/60 text-left">
                  <tr>
                    <th scope="col" className="pb-3 pt-6 px-6">
                      Vendor
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6">
                      Date added
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6">
                      Type
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6 text-center">
                      Good supplied
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6">
                      Location
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6 text-center">
                      Terms of trade
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6 text-center">
                      Rating
                    </th>
                    <th scope="col" className="pb-3 pt-6 px-6 text-left">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredData || vendorsData?.docs || [])?.map((item) => (
                    <tr
                      className="bg-white/60 cursor-pointer"
                      key={item._id}
                      onClick={() => navigate(`/vendors/${item.vendorID?._id}`)}
                    >
                      <th
                        scope="row"
                        className="p-5 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.vendorName.length > 13
                          ? `${item.vendorName.slice(0, 13)}...`
                          : item.vendorName}
                      </th>
                      <td className="px-6 py-4">
                        {format(new Date(item.createdAt), 'dd MMM yyyy')}
                      </td>
                      <td className="px-6 py-4">
                        {item.type === 'buyer' ? 'Buyer' : 'Supplier'}
                      </td>
                      <td className="px-6 py-4 text-center">{item.goodsSupplied}</td>
                      <td className="px-6 py-4">
                        {item.active ? (
                          <>
                            {item.vendorID!.addresses.length > 0 && (
                              <>
                                {item.vendorID!.addresses[0].state},{' '}
                                {item.vendorID!.addresses[0].country}
                              </>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="px-6 py-4 text-center">{item.termsOfTrade}</td>
                      <td className="px-6 py-4 mx-auto text-center">
                        <Rating rating={item.rating ? item.rating : 0} />
                      </td>
                      <td className="px-6 py-4 text-center">
                        <Status
                          status={
                            item.blacklisted
                              ? 'Blacklisted'
                              : item.active
                              ? 'Active'
                              : item.vendorID?.status === 'pending'
                              ? 'Pending'
                              : item.vendorID?.status === 'Unregistered'
                              ? 'Unregistered'
                              : 'Not registered'
                          }
                          title={
                            item.blacklisted
                              ? 'Blacklisted'
                              : item.active
                              ? 'Active'
                              : item.vendorID?.status === 'pending'
                              ? 'Pending'
                              : item.vendorID?.status === 'Unregistered'
                              ? 'Unregistered'
                              : 'Not registered'
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalCount={vendorsData?.totalPages}
                pageSize={10}
                onPageChange={(page: any) => {
                  setCurrentPage(page);
                  paginationClick(page);
                }}
              />
              {!(filteredData || vendorsData?.docs || [])?.length && (
                <EmptyRecord
                  title="No vendor to show yet"
                  content="You’ve got a  blank state. Add vendor to get started"
                  icon={Icons.Vendor}
                />
              )}
            </div>
          )}
        </CheckPermissions>
      </div>

      {showAddVendorMenu && (
        <EnhancedAddVendorMenu
          onCancel={() => setShowAddVendorMenu(false)}
          onAddBulk={() => {
            setShowAddVendorMenu(false);
            setShowAddMultipleVendor(true);
          }}
          onAddOneByOne={() => {
            setShowAddSingleVendor(true);
            setShowAddVendorMenu(false);
          }}
        />
      )}
      {showAddSingleVendor && (
        <EnhancedAddVendorModal
          onCancel={() => setShowAddSingleVendor(false)}
          onSuccess={() => {
            fetchData(currentPage);
            setShowSuccessModal(true);
            setShowAddSingleVendor(false);
          }}
          businessId={docs[0]?.businessID._id}
        />
      )}
      {showAddMultipleVendor && (
        <EnhancedAddBulkVendorModal
          onCancel={() => setShowAddMultipleVendor(false)}
          onSuccess={() => {
            setShowSuccessModal(true);
            setShowAddMultipleVendor(false);
          }}
        />
      )}

      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Vendor added successfully"
          description="Your vendor list has been updated"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}
