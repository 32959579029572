import React, { useState } from 'react';
import { InvoiceDoc } from '../../../types/invoice.type';
import { useNavigate } from 'react-router-dom';
import { deleteAnInvoice, markInvoice, sendRemainder } from '../../../service/invoice';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import withCreatePortal from '../../Hoc/withCreatePortal';
import ConfirmModal from '../ConfirmModal';

interface IProps {
  className?: string;
  onClick?: () => void;
  invoice?: InvoiceDoc | null;
  businessId?: string;
  name?: string;
  email: string;
  setInvoiceData: (arg: any) => void;
  visibility: string;
}

const EnhancedConfirmModal = withCreatePortal(ConfirmModal);

const Action: React.FC<IProps> = ({
  invoice,
  businessId,
  setInvoiceData,
  visibility,
  name,
  email,
}) => {
  const navigate = useNavigate();
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);

  const deleteInvoiceId = async (businessId: any, invoiceId: string | undefined) => {
    await deleteAnInvoice(businessId, (invoiceId && invoiceId) || '')
      .then(() => {
        toast.success('InvoiceId  deleted successfully');
        setInvoiceData((prev: any) =>
          prev.docs.filter((res: any) => res?._id === invoiceId)
        );
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err.message ?? 'Sorry, An error as occurred');
      });
  };

  const sendARemainder = async (businessId: any, invoiceId: string | undefined) => {
    const payload = {
      email: `${invoice?.email}`,
      cc: [
        {
          email: `${email}`,
        },
      ],
      message: `This is a reminder your Invoice ${invoice?.number} sent to ${name} is now overdue. Log-in to view the invoice and send a reminder. You can also confirm payment if you have received payment for this invoice. Thank you, Paytton `,
    };
    if (invoiceId) {
      await sendRemainder(businessId, invoiceId, payload)
        .then(() => {
          toast.success('A remainder has been sent successfully');
        })
        .catch((error) => {
          const err = error as AxiosError<Error>;
          toast.error(err.message ?? 'Sorry, An error as occurred');
        });
    }
  };

  const markAsPaid = async (
    businessId: any,
    invoiceId: string | undefined,
    amount: any
  ) => {
    const payload = {
      amount: amount,
    };
    if (invoiceId) {
      await markInvoice(businessId, invoiceId, payload)
        .then(() => {
          toast.success('InvoiceId  deleted successfully');
          setInvoiceData((prev: any) =>
            prev.docs.filter((res: any) => res?._id === invoiceId)
          );
        })
        .catch((error) => {
          const err = error as AxiosError<Error>;
          toast.error(err.message ?? 'Sorry, An error as occurred');
        });
    }
  };

  return (
    <div>
      <div
        className={`${
          visibility === 'draft'
            ? 'absolute left-[-90px] bottom-[-160px] z-[10] flex flex-col justify-center items-center bg-white py-4 w-[150px] '
            : visibility === 'seller_internal_approved'
            ? 'absolute left-[-90px] bottom-[-210px] z-[10] flex flex-col justify-center items-center bg-white py-4 w-[150px]'
            : 'absolute left-[-90px] bottom-[-50px] z-[10] flex flex-col justify-center items-center bg-white py-4 w-[150px]'
        } `}
      >
        <ul className=" flex flex-col gap-3 w-[100%] px-2 mx-auto">
          <li
            onClick={() => {
              navigate(`/invoices/view/${invoice?._id}`);
            }}
            className="py-1 hover:bg-[#F7F9FB] hover:text-black"
          >
            {' '}
            <button className="px-4 py-1"> View </button>
          </li>
          {visibility === 'draft' && (
            <li
              onClick={() => {
                navigate(`/invoices/create`);
              }}
              className="py-1 hover:bg-[#F7F9FB]"
            >
              <button className="py-1 px-4">Edit</button>{' '}
            </li>
          )}

          {visibility === 'seller_internal_approved' && (
            <li className="py-1 hover:bg-[#F7F9FB]">
              <button
                className="px-4 py-1"
                onClick={() => sendARemainder(businessId, invoice?._id && invoice._id)}
              >
                Send reminder{' '}
              </button>{' '}
            </li>
          )}
          {visibility === 'seller_internal_approved' && (
            <li className="py-1 hover:bg-[#F7F9FB]">
              <button
                className="px-4 py-1"
                onClick={() => markAsPaid(businessId, invoice?._id, invoice?.amount)}
              >
                Mark as paid{' '}
              </button>{' '}
            </li>
          )}
          {visibility === 'seller_internal_approved' && (
            <li className="py-1 hover:bg-[#F7F9FB]">
              <button
                onClick={() => setConfirmationModalActive(true)}
                className="px-4 py-1"
              >
                Make payment{' '}
              </button>{' '}
            </li>
          )}
          {visibility === 'draft' && (
            <li className={`py-1 hover:bg-[#F7F9FB]`}>
              <button
                onClick={() => {
                  deleteInvoiceId(businessId, invoice?._id && invoice._id);
                }}
                className="px-4 py-1"
              >
                Delete{' '}
              </button>{' '}
            </li>
          )}
        </ul>
      </div>
      {confirmationModalActive && (
        <EnhancedConfirmModal
          title="Make a payment"
          content={`Payment would be made to ${invoice?.sellerName}. `}
          actionText="Make Payment"
          onCancel={() => {
            setConfirmationModalActive(false);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-fit px-3 py-3 text-sm bg-[#061A40] rounded font-normal text-white"
          onPaymentClose={() => {
            toast.error('Payment close');
          }}
          email={email}
          amount={invoice?.amount}
          payStackBtnStyle={
            'rounded-[10px] text-base font-medium w-full bg-[#061A40] p-3 text-white'
          }
          paystackBtnText="Make Payment"
          continuePayment={true}
        />
      )}
    </div>
  );
};

export default Action;
