import React from 'react';

type Props = {
  rating: number;
  onClick?: (rating: number) => void;
  width?: number;
  height?: number;
};

export default function Rating({ rating, onClick, width, height }: Props) {
  const maxStars = 5;
  const stars = Array.from({ length: maxStars }, (_, index) => (
    <svg
      width={width ?? '19'}
      height={height ?? '18'}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick(index + 1)}
    >
      <path
        opacity="0.2"
        d="M9.99742 13.4091L13.5412 15.6591C13.9982 15.9474 14.5607 15.5185 14.4271 14.9911L13.4005 10.9552C13.3728 10.8433 13.3772 10.7259 13.4133 10.6164C13.4494 10.5069 13.5156 10.4099 13.6045 10.3364L16.7826 7.68566C17.1974 7.34113 16.9865 6.64504 16.4451 6.60988L12.2966 6.34269C12.1834 6.33611 12.0746 6.29669 11.9835 6.22926C11.8923 6.16183 11.8228 6.06931 11.7834 5.96301L10.2365 2.06769C10.1955 1.95512 10.1209 1.85788 10.0228 1.78917C9.92471 1.72045 9.80783 1.68359 9.68805 1.68359C9.56826 1.68359 9.45138 1.72045 9.35326 1.78917C9.25515 1.85788 9.18056 1.95512 9.13961 2.06769L7.59273 5.96301C7.55331 6.06931 7.48377 6.16183 7.39262 6.22926C7.30146 6.29669 7.19264 6.33611 7.07945 6.34269L2.93102 6.60988C2.38961 6.64504 2.17867 7.34113 2.59352 7.68566L5.77164 10.3364C5.86047 10.4099 5.92674 10.5069 5.96282 10.6164C5.99889 10.7259 6.0033 10.8433 5.97555 10.9552L5.02633 14.6958C4.86461 15.3286 5.53961 15.8419 6.08101 15.4974L9.37867 13.4091C9.47114 13.3503 9.57846 13.3191 9.68805 13.3191C9.79763 13.3191 9.90495 13.3503 9.99742 13.4091Z"
        fill={index + 1 <= rating ? '#0353A4' : 'none'}
      />
      <path
        d="M9.99742 13.4091L13.5412 15.6591C13.9982 15.9474 14.5607 15.5185 14.4271 14.9911L13.4005 10.9552C13.3728 10.8433 13.3772 10.7259 13.4133 10.6164C13.4494 10.5069 13.5156 10.4099 13.6045 10.3364L16.7826 7.68566C17.1974 7.34113 16.9865 6.64504 16.4451 6.60988L12.2966 6.34269C12.1834 6.33611 12.0746 6.29669 11.9835 6.22926C11.8923 6.16183 11.8228 6.06931 11.7834 5.96301L10.2365 2.06769C10.1955 1.95512 10.1209 1.85788 10.0228 1.78917C9.92471 1.72045 9.80783 1.68359 9.68805 1.68359C9.56826 1.68359 9.45138 1.72045 9.35326 1.78917C9.25515 1.85788 9.18056 1.95512 9.13961 2.06769L7.59273 5.96301C7.55331 6.06931 7.48377 6.16183 7.39262 6.22926C7.30146 6.29669 7.19264 6.33611 7.07945 6.34269L2.93101 6.60988C2.38961 6.64504 2.17867 7.34113 2.59352 7.68566L5.77164 10.3364C5.86047 10.4099 5.92674 10.5069 5.96282 10.6164C5.99889 10.7259 6.0033 10.8433 5.97555 10.9552L5.02633 14.6958C4.86461 15.3286 5.53961 15.8419 6.08101 15.4974L9.37867 13.4091C9.47114 13.3503 9.57846 13.3191 9.68805 13.3191C9.79763 13.3191 9.90495 13.3503 9.99742 13.4091V13.4091Z"
        stroke="#0353A4"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ));
  return <div className="flex justify-center items-center">{stars}</div>;
}
