import React, { useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import SearchInput from '../../../../Components/Form/SearchInput';
import FilterDropdown from '../../../../Components/Filter/FilterDropdown';
import { Icon, Icons } from '../../../../Components/Icon';
import EmptyRecord from '../../../../Components/EmptyRecord';
import Button from '../../../../Components/Form/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { getInvoices } from '../../../../service/invoice';
import { InvoiceDoc, InvoiceType } from '../../../../types/invoice.type';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import Loader from '../../../../Assests/loader.gif';
import { formatCurrency, getDateStatus } from '../../../../utils';
import { format } from 'date-fns';
import Status from '../../../../Components/Status';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../../Context/Context';
import { SlOptionsVertical } from 'react-icons/sl';
// import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import Action from '../../../../Components/Modal/Invoice/Action';

// const EnhancedActionModal = withCreatePortal(Action);

export default function Invoice() {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [invoicesData, setInvoicesData] = useState<InvoiceType | null>(null);
  const [filterValue, setFilterValue] = useState('supplier');
  const [filteredData, setFilteredData] = useState<InvoiceDoc[] | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState<InvoiceDoc | null>();
  const [indexing, setIndexing] = useState<number | null>();

  const [currentPage] = useState(1);
  const navigate = useNavigate();
  const { setSelectedInvoiceId } = useGlobalContext();

  const { docs } = useAppSelector(selectBusiness);

  const fetchData = useCallback(
    async (page: number, filterBy?: string) => {
      const invoices = await getInvoices(docs[0].businessID._id, filterBy, page);
      setIsLoading(false);
      setInvoicesData(invoices);
    },
    [docs]
  );

  useEffect(() => {
    docs[0].businessID.status === 'active'
      ? null
      : toast.info('You need to verify your business first');
    setIsLoading(true);
    fetchData(currentPage, 'supplier');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectFilter = (filterBy: string) => {
    setIsLoading(true);
    fetchData(currentPage, filterBy ? filterBy : 'supplier');
    setFilterValue(filterBy);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    const filteredData = invoicesData?.docs.filter((item: any) => {
      const SearchMacth = item.number.toLowerCase().includes(searchTerm.toLowerCase());

      if (filterValue === 'buyer') {
        const bussinesName = item.businessID.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return bussinesName || SearchMacth;
      } else {
        const sellersName = item.sellerName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return sellersName || SearchMacth;
      }
    });
    setFilteredData(filteredData as InvoiceDoc[]);
  };

  //pagination
  // const paginationClick = (num: any) => {
  //   setIsLoading(true);
  //   if (filterValue) {
  //     if (filterValue === 'supplier') {
  //       fetchData(num);
  //     } else {
  //       fetchData(num);
  //     }
  //   } else {
  //     fetchData(num, 'supplier');
  //   }
  // };

  const shortenName = (name: string) => {
    if (!isLoading) {
      if ((name ?? '')?.length > 10) {
        return `${name.slice(0, 10)}...`;
      } else {
        return name;
      }
    }
  };

  const getStatusLabel = (status: string, filterValue: string) => {
    switch (status) {
      case 'awaiting_internal_approval':
        return 'Awaiting Internal Approval';
      case 'approved':
        return 'Approved Internally';
      case 'declined_internally':
        return 'Declined Internally';
      case 'awaiting_customer_approval':
        return filterValue === 'buyer' ? 'Awaiting your approval' : 'Sent to Customer';
      case 'customer_declined':
        return 'Declined by Customer';
      case 'approved_for_financing':
        return 'Approved by Customer';
      case 'outstanding':
        return 'Outstanding';
      case 'draft':
        return 'Draft';
      case 'seller_internal_approved':
        return 'Seller Internal Approval';
      default:
        return 'Fully Paid';
    }
  };

  return (
    <div>
      <Breadcrumb text="Invoices" />
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <CheckPermissions requiredPermissions={['view_invoice']}>
            <div className="w-1/3">
              <SearchInput
                value={search}
                placeholder="Search"
                name={''}
                className="h-10"
                onChange={handleSearch}
              />
            </div>
          </CheckPermissions>
          <div className="flex items-center gap-2">
            <CheckPermissions requiredPermissions={['view_invoice']}>
              <FilterDropdown
                iconPosition="left"
                icon={<Icon name={Icons.Filter} />}
                setSelectedOrder={(val) => console.log('FilterDropdown', val)}
                label="Filter"
                handleTableSearch={(val) => selectFilter(val)}
                options={[
                  { value: 'supplier', label: 'Receivables' },
                  { value: 'buyer', label: 'Payables' },
                ]}
                disabled={docs[0].businessID.status === 'active' ? false : true}
              />
            </CheckPermissions>
            <CheckPermissions requiredPermissions={['create_invoice', '*']}>
              <Link to="create">
                <Button
                  type="button"
                  variant="primary"
                  size="custom"
                  label="Create Invoice"
                  className="px-5 py-2.5 text-sm"
                  icon={<Icon name={Icons.Add} fill="#FFFFFF" width={15} height={15} />}
                  iconPosition="left"
                  iconLabelSpacing={10}
                  disabled={docs[0].businessID.status === 'active' ? false : true}
                />
              </Link>
            </CheckPermissions>
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="" />
          </div>
        ) : (
          <div className="relative overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
            <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
              <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                <tr>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Invoice No.
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Vendor
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Due Date
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Stages
                  </th>
                  <th scope="col" className="px-6 pt-6 pb-3">
                    Status
                  </th>
                  {(filteredData || invoicesData?.docs || [])?.some(
                    (item) => item.status === 'draft'
                  ) && <th> Action </th>}
                </tr>
              </thead>
              <tbody>
                {(filteredData || invoicesData?.docs || [])?.map((item, index) => (
                  <tr className="bg-white/60">
                    <th
                      scope="row"
                      className="p-5 px-6 font-medium text-gray-900 cursor-pointer hover:underline whitespace-nowrap dark:text-white"
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                    >
                      {item.number}
                    </th>
                    <td
                      className="px-6 py-4 cursor-pointer hover:underline"
                      onClick={() => {
                        if (filterValue === 'supplier') {
                          navigate(`/vendors/${item.sellerID._id}`);
                        } else {
                          navigate(`/vendors/${item.businessID._id}`);
                        }
                      }}
                    >
                      {filterValue === 'buyer' ? (
                        <p>{shortenName(item.businessID.name)}</p>
                      ) : (
                        <p>{shortenName(item.sellerName)}</p>
                      )}
                    </td>
                    <td className="px-6 py-4 cursor-pointer  hover:underline">
                      {formatCurrency(item.currency, item.amount)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-4 cursor-pointer hover:underline"
                    >
                      {format(new Date(item.date), 'dd, MMM yyyy')}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-4 cursor-pointer hover:underline"
                    >
                      {getDateStatus(item.requiredEnd)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-4 cursor-pointer  hover:underline"
                    >
                      {getStatusLabel(item.status, filterValue)}
                    </td>
                    <td
                      onClick={() => navigate(`/invoices/view/${item._id}`)}
                      className="px-6 py-4 cursor-pointer hover:underline"
                    >
                      <Status
                        status={item.paid ? 'Active' : 'Not registered'}
                        title={item.paid ? 'Paid' : 'Pending'}
                      />
                    </td>
                    {
                      <td className="px-6 py-4 relative z-4 cursor-pointer hover:underline ">
                        <button
                          className="relative"
                          onClick={() => {
                            setSelectedInvoiceId(item._id);
                            setInvoiceId(item);
                            setSelectedInvoiceId(item._id);
                            setIndexing((prevIndex) =>
                              prevIndex === index ? null : index
                            );
                            setShowModal((prev) => !prev);
                          }}
                        >
                          <SlOptionsVertical />
                        </button>
                        {showModal && index === indexing && (
                          <Action
                            invoice={invoiceId}
                            email={docs[0].businessID.email}
                            name={docs[0].firstName + ' ' + docs[0].lastName}
                            businessId={docs[0].businessID._id}
                            setInvoiceData={setInvoicesData}
                            visibility={item.status}
                          />
                        )}
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
            {!(filteredData || invoicesData?.docs || [])?.length && (
              <EmptyRecord
                title="No invoice to show yet"
                content="You’ve got a  blank state. Create invoice to get started"
                icon={Icons.Invoices}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
