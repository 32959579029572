import { useState, useRef } from 'react';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Icon, Icons } from '../Components/Icon';
import Avatar from '../Assests/avatar.png';
import Logo from '../Assests/logo.png';
import { determinePathName } from '../utils';
import { logout } from '../thunks/account-thunk';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectBusiness } from '../selectors/business-selector';
import ImageWithFallback from '../Components/ImageDisplay/ImageDisplay';
import useOnClickOutside from '../CustomHooks/useClickOutside';

export default function UserLayout() {
  const [showNav, setShowNav] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { docs } = useAppSelector(selectBusiness);

  const ref = useRef<any>();

  const onClose = () => {
    setShowNav(false);
  };

  useOnClickOutside(ref, onClose);

  const renderNavLink = (icon: Icons, navName: string, path: string): JSX.Element => (
    <li className="nav-item">
      <NavLink
        to={path}
        className={`group ${determinePathName(pathname) === path ? 'active' : ''}`}
      >
        <div className="flex items-center pl-6">
          <Icon
            name={icon}
            fill={determinePathName(pathname) === path ? '#000000' : '#FFFFFF'}
          />
          <span
            className={`ltr:pl-3 rtl:pr-3 ${
              determinePathName(pathname) === path ? 'text-black' : 'text-white/80'
            }`}
          >
            {navName}
          </span>
        </div>
      </NavLink>
    </li>
  );

  const onNavigate = (path: string) => {
    setShowNav(false);
    navigate(path);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/auth/login');
  };

  return (
    <div className="min-h-screen text-black main-container">
      <nav className="sidebar fixed top-0 bottom-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300 bg-[#0353A4]">
        <div className="h-full bg-[#0353A4] text-white perfect-scrollbar relative space-y-0.5 overflow-y-auto overflow-x-hidden pb-28">
          <div className="flex items-center justify-between px-4 py-3">
            <Link to="/dashboard" className="flex items-center main-logo shrink-0">
              <img className="ml-[5px] flex-none mt-8" src={Logo} alt="logo" />
            </Link>
          </div>
          <ul className="flex flex-col font-semibold gap-44 mt-14 h-[calc(100vh-240px)]">
            <div className="relative text-sm font-normal text-white mt-9">
              <li className="text-base font-medium nav-item text-white/80 font-inter">
                <ul>
                  {renderNavLink(Icons.Dashboard, 'Dashboard', '/dashboard')}
                  {renderNavLink(Icons.Vendor, 'Vendors', '/vendors')}
                  {renderNavLink(Icons.Invoices, 'Invoices', '/invoices')}
                  {renderNavLink(Icons.Product, 'Products', '/products')}
                  {renderNavLink(Icons.Transaction, 'Transactions', '/transactions')}
                  {renderNavLink(Icons.Audit, 'Audit Trail', '/audit-trail')}
                  {renderNavLink(Icons.Settings, 'Settings', '/settings')}
                </ul>
              </li>
            </div>
            <li className="mt-auto ms-9">
              <div
                className="cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={handleLogout}
              >
                <div className="flex items-center w-2/3 p-3 px-5 border rounded-lg">
                  <Icon name={Icons.Logout} />
                  <span className="font-normal text-white ltr:pl-3 rtl:pr-3">Logout</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {/* end sidebar section */}
      <div className="flex flex-col min-h-screen main-content">
        <header className="z-40">
          <div className="shadow-sm">
            <div className="relative flex items-center justify-between w-full px-5 bg-white py-7 rounded-2xl">
              <div className="hidden ltr:mr-2 rtl:ml-2 sm:block">
                <p className="text-2xl font-medium text-black font-inter">
                  {docs[0]?.businessName}
                </p>
              </div>
              <div
                className="flex items-center space-x-1.5 gap-4 ssm:flex-1 lg:space-x-2"
                ref={ref}
              >
                <Icon name={Icons.Bell} />
                <div className="flex-shrink-0 pl-5 border-l dropdown">
                  <div
                    role="button"
                    tabIndex={0}
                    className="relative cursor-pointer group"
                    onClick={() => setShowNav((prevState) => !prevState)}
                  >
                    <ImageWithFallback
                      src={docs[0]?.profileImageURL ?? Avatar}
                      alt="Image Description"
                      className="object-cover w-10 h-10 rounded-full saturate-50 group-hover:saturate-100"
                    />
                  </div>
                  {showNav && (
                    <ul className="z-50 top-11 w-[270px] !py-0 font-semibold text-dark -right-14 bg-white/30">
                      <li
                        className="cursor-pointer"
                        onClick={() => onNavigate('/profile')}
                      >
                        <div className="flex items-center px-4 py-4">
                          <div className="flex-none">
                            <img
                              className="object-cover rounded-md h-11 w-11"
                              src={Avatar}
                              alt="avatar"
                            />
                          </div>
                          <div className="truncate ltr:pl-4 rtl:pr-4">
                            <h4 className="text-base font-medium font-inter">
                              {docs[0]?.businessName}
                            </h4>
                            <span className="text-xs text-black/50">
                              {docs[0]?.email}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="border-t border-white-light">
                        <p className="font-inter font-medium text-[10px] p-4">
                          Switch accounts
                        </p>
                        <div
                          role="button"
                          tabIndex={0}
                          className="flex items-center gap-3 p-3 text-sm font-medium cursor-pointer font-inter"
                          onClick={() => onNavigate('switch-account')}
                        >
                          <Icon name={Icons.Add} />
                          Switch account
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className="cursor-pointer"
                  onClick={() => setShowNav((prevState) => !prevState)}
                >
                  {showNav ? (
                    <Icon name={Icons.CaretUp} />
                  ) : (
                    <Icon name={Icons.CaretDown} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="py-5 pl-0 animate__animated md:py-5">
          {/* start main content section */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
