import React, { Fragment, useEffect, useState } from 'react';
import { InvoiceDoc, InvoiceStatus } from '../../../../types/invoice.type';
import { useGetApproval } from '../../../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { groupDataByLevel } from '../../../../utils';
import Button from '../../../../Components/Form/Button';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import DeclineModal from '../../../../Components/Modal/Invoice/DeclineModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import { Icon, Icons } from '../../../../Components/Icon';
import { selectUserInformation } from '../../../../selectors/account-selector';
import {
  useApproveInvoice,
  useDeclineInvoice,
} from '../../../../hooks/queries-and-mutations/invoice';
import { toast } from 'react-toastify';
import SendReminderModal from '../../../../Components/Modal/Invoice/SendReminderModal';

type IProps = {
  sellerApprovalNextLevel: string | undefined;
  invoice: InvoiceDoc | undefined;
  getInvoice: () => void;
};

const EnhancedDeclineModal = withCreatePortal(DeclineModal);
const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);
const EnhancedSendReminderModal = withCreatePortal(SendReminderModal);

export default function ReceiveableApprovalFlow({
  invoice,
  sellerApprovalNextLevel,
  getInvoice,
}: IProps) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSuccessModalForReminder, setShowSuccessModalForReminder] = useState(false);

  const { docs } = useAppSelector(selectBusiness);
  const { user } = useAppSelector(selectUserInformation);

  const { data: approvals } = useGetApproval({ businessId: docs[0]?.businessID?._id });
  const {
    mutate: approveInvoice,
    isSuccess: isApproveInvoiceSuccess,
    isError: isApproveInvoiceError,
  } = useApproveInvoice();
  const {
    mutate: declineInvoice,
    isSuccess: isDeclineInvoiceSuccess,
    isError: isDeclineInvoiceError,
  } = useDeclineInvoice();

  const groupedData = groupDataByLevel(approvals?.docs ?? []);

  //Check if the person has approved
  const checkUserApproval = (approvalUsers: any) => {
    const foundUser = approvalUsers[sellerApprovalNextLevel as any]?.find(
      (item: any) => item.email === user.email
    );
    if (foundUser) {
      return foundUser;
    } else {
      return null;
    }
  };

  const currentUserApproval = checkUserApproval(groupedData.receivable);

  const getBackgroundColor = (currentStatus: string) => {
    const status = invoice?.status;
    if (
      currentStatus === InvoiceStatus.AWAITING_APPROVAL_SELLER &&
      status === InvoiceStatus.AWAITING_APPROVAL_SELLER
    ) {
      return {
        bg: 'bg-[#0353A4]',
        icon: 'white',
        border: 'after:border-[#0353A4]',
      };
    } else if (
      status === InvoiceStatus.APPROVED &&
      (currentStatus === InvoiceStatus.APPROVED ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_SELLER)
    ) {
      return {
        bg: 'bg-[#0353A4]',
        icon: 'white',
        border: 'after:border-[#0353A4]',
      };
    } else if (
      status === InvoiceStatus.AWAITING_APPROVAL_BUYER &&
      (currentStatus === InvoiceStatus.APPROVED ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_SELLER ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_BUYER)
    ) {
      return {
        bg: 'bg-[#0353A4]',
        icon: 'white',
        border: 'after:border-[#0353A4]',
      };
    } else if (
      status === InvoiceStatus.APPROVED_FOR_FUNDING &&
      (currentStatus === InvoiceStatus.APPROVED ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_SELLER ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_BUYER ||
        currentStatus === InvoiceStatus.APPROVED_FOR_FUNDING)
    ) {
      return {
        bg: 'bg-[#0353A4]',
        icon: 'white',
        border: 'after:border-[#0353A4]',
      };
    } else if (
      status === InvoiceStatus.PAID &&
      (currentStatus === InvoiceStatus.APPROVED ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_SELLER ||
        currentStatus === InvoiceStatus.AWAITING_APPROVAL_BUYER ||
        currentStatus === InvoiceStatus.APPROVED_FOR_FUNDING ||
        currentStatus === InvoiceStatus.PAID)
    ) {
      return {
        bg: 'bg-[#0353A4]',
        icon: 'white',
        border: 'after:border-[#0353A4]',
      };
    } else {
      return {
        bg: 'bg-[#EBEBEBE5]',
        icon: 'black',
        border: 'after:border-[#EBEBEBE5]',
      };
    }
  };

  const handleApprove = () => {
    setIsSubmitting(true);
    approveInvoice({ businessId: docs[0]?.businessID?._id, invoiceId: invoice?._id });
  };

  const handleDecline = (reason: any) => {
    setIsSubmitting(true);
    declineInvoice({
      businessId: docs[0]?.businessID?._id,
      invoiceId: invoice?._id,
      payload: {
        note: reason,
      },
    });
  };

  useEffect(() => {
    if (isApproveInvoiceSuccess && !isApproveInvoiceError) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setIsSubmitting(false);
      getInvoice();
    } else if (isApproveInvoiceError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveInvoiceError, isApproveInvoiceSuccess]);

  useEffect(() => {
    if (isDeclineInvoiceSuccess && !isDeclineInvoiceError) {
      setShowDeclineModal(false);
      setIsSubmitting(false);
      toast.success('You have successfully decline this invoice.');
      getInvoice();
    } else if (isDeclineInvoiceError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeclineInvoiceError, isDeclineInvoiceSuccess]);

  return (
    <div>
      {/* Status */}
      <div className="ml-10 px-8 my-10 w-full">
        <ol className="flex items-center w-full">
          {/* <div className='w-full flex flex-col justify-center gap-y-4'>
            <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#EBEBEBE5] after:border-4 after:inline-block">
              <span className="flex items-center justify-center w-12 h-12 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                <Icon name={Icons.Sent} />
              </span>
            </li>
            <span className='w-[5rem] h-10'>Sent for approval</span>
          </div> */}
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).border
              } after:border-4 after:inline-block`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Awaiting}
                  fill={getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_SELLER).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] h-10">Awaiting approval</span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.APPROVED).border
              } after:border-4 after:inline-block`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.APPROVED).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Approve}
                  fill={getBackgroundColor(InvoiceStatus.APPROVED).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Invoice approved</span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).border
              } after:border-4 after:inline-block`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Sent}
                  fill={getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Sent to customer</span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).border
              } after:border-4 after:inline-block`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Awaiting}
                  fill={getBackgroundColor(InvoiceStatus.AWAITING_APPROVAL_BUYER).icon}
                />
              </span>
            </li>
            <span className="w-[9rem] text-center -ml-9 h-10">
              Awaiting customer approval
            </span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li
              className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
                getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).border
              } after:border-4 after:inline-block`}
            >
              <span
                className={`flex items-center justify-center w-12 h-12 ${
                  getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Payment}
                  fill={getBackgroundColor(InvoiceStatus.APPROVED_FOR_FUNDING).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Awaiting payment</span>
          </div>
          <div className="w-full flex flex-col justify-center gap-y-4">
            <li className="flex w-full items-center">
              <span
                className={`flex items-center justify-center w-10 h-10 ${
                  getBackgroundColor(InvoiceStatus.PAID).bg
                } rounded-full lg:h-14 lg:w-14 shrink-0`}
              >
                <Icon
                  name={Icons.Approve}
                  fill={getBackgroundColor(InvoiceStatus.PAID).icon}
                />
              </span>
            </li>
            <span className="w-[5rem] text-center -ml-4 h-10">Payment completed</span>
          </div>
          {/* <div className='w-full'>
              <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#0353A4] after:border-4 after:inline-block">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Awaiting} />
                </span>
              </li>
              <span>Awaiting customer approval</span>
            </div>
            <div className='w-full'>
              <li className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#0353A4] after:border-4 after:inline-block">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Payment} />
                </span>
              </li>
              <span>Awaiting payment</span>
            </div>
            <div className='w-full'>
              <li className="flex w-full items-center ">
                <span className="flex items-center justify-center w-10 h-10 bg-[#0353A4] rounded-full lg:h-14 lg:w-14 shrink-0">
                  <Icon name={Icons.Approve} />
                </span>
              </li>
              <span>Payment completed</span>
            </div> */}
        </ol>
      </div>

      <div className="bg-[#B9D6F23D] rounded-2xl p-10 mx-10 mb-5">
        {invoice?.status === 'awaiting_internal_approval' && (
          <div className="flex justify-between items-center divide-x divide-black/10">
            <div className="flex justify-between items-center gap-x-16">
              {Object.keys(groupedData.receivable).map((level) => (
                <Fragment key={level}>
                  <div className="flex flex-col gap-1">
                    <p className="text-black/50 font-inter font-medium text-sm">
                      Level {level}
                    </p>
                    <p className="text-black font-inter font-medium text-xl">{`${
                      currentUserApproval?.firstName ??
                      groupedData.receivable[level][0].firstName
                    } ${
                      currentUserApproval?.lastName ??
                      groupedData.receivable[level][0].lastName
                    }`}</p>
                    {level === sellerApprovalNextLevel ? (
                      <div className="whitespace-nowrap text-sm mt-2">
                        <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#216509E8] rounded-full"></span>
                        Approved
                      </div>
                    ) : (
                      <div className="whitespace-nowrap text-sm mt-2">
                        <span className="mr-2 inline-flex items-center justify-center w-2.5 h-2.5 bg-[#F48D14E8] rounded-full"></span>
                        {currentUserApproval
                          ? 'Awaiting your approval'
                          : 'Awaiting approval'}
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>

            {checkUserApproval(groupedData.receivable) && (
              <div className="flex gap-6 items-center pl-10">
                <Button
                  type="button"
                  variant="custom"
                  size="custom"
                  label="Approve Invoice"
                  className="px-5 py-2.5 text-sm bg-[#0353A4] text-white"
                  onClick={() => setShowConfirmModal(true)}
                />
                <Button
                  type="button"
                  variant="custom"
                  size="custom"
                  label="Decline Invoice"
                  className="px-5 py-2.5 text-sm bg-[#AA1414] text-white"
                  onClick={() => setShowDeclineModal(true)}
                />
              </div>
            )}
          </div>
        )}

        {invoice?.status === 'approved_for_financing' && (
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-1">
              <p className="text-black/50 font-inter font-medium text-sm">Amount Paid</p>
              <p className="text-black font-inter font-medium text-xl">₦170,000.00</p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-black/50 font-inter font-medium text-sm">
                Outstanding Amount{' '}
              </p>
              <p className="text-black font-inter font-medium text-xl">₦100,000.00</p>
            </div>
            <div className="border-r border-black/10 max-h-20 h-12" />
            <div className="pl-10">
              {/* <div className='flex flex-col gap-1 w-2/3'>
                <p className='text-black font-inter font-medium text-sm'>Favourite promise has made payment of ₦100,000.00</p>
                <span onClick={() => setShowPaymentProofModal(true)} className='cursor-pointer text-[#0353A4] font-inter font-medium text-sm'>View Proof of Payment</span>
              </div> */}
              {invoice?.status === 'approved_for_financing' && (
                <Button
                  type="button"
                  variant="primary"
                  size="custom"
                  label="Send Reminder"
                  className="px-5 py-2.5 text-sm text-white"
                  onClick={() => setShowReminderModal(true)}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {showDeclineModal && (
        <EnhancedDeclineModal
          onCancel={() => setShowDeclineModal(false)}
          onSuccess={(values: any) => handleDecline(values)}
          isSubmitting={isSubmitting}
        />
      )}

      {showConfirmModal && (
        <EnhancedConfirmModal
          content={'Are you sure you want to approve this invoice ?'}
          onConfirm={() => {
            handleApprove();
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
          isSubmitting={isSubmitting}
        />
      )}

      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Invoice Approved"
          description="You have approved this invoice "
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {showReminderModal && (
        <EnhancedSendReminderModal
          onCancel={() => setShowReminderModal(false)}
          onSuccess={() => {
            setShowReminderModal(false);
            setShowSuccessModalForReminder(true);
          }}
        />
      )}
      {showSuccessModalForReminder && (
        <EnhancedSuccessModal
          title="Reminder Sent"
          description="A reminder has been sent to Favorite Promise"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}
